





























































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";
import { RouteStop, RouteStopSearch } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";


@Component({
    components: {
        pagination,
    }
})
export default class RouteStopListView extends Vue {
    qUsers: RouteStop[] = [];
    params: RouteStopSearch = {} as RouteStopSearch;

    async created(){
      await metaStore.getRouteStop(this.params);
    }

    get AdminRouter() {
        return AdminRouter;
    }

    get routeStopList() {
        return metaStore.routeStopList;
    }
} 
